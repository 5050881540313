var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayName
    ? _c(
        "div",
        { staticClass: "the-navbar__user-meta flex items-center" },
        [
          _c(
            "div",
            { staticClass: "text-right leading-tight hidden sm:block" },
            [
              _c("p", { staticClass: "font-semibold" }, [
                _vm._v(_vm._s(_vm.displayName)),
              ]),
              _c("small", [_vm._v(" " + _vm._s(_vm.$t("vue.available")))]),
            ]
          ),
          _c(
            "vs-dropdown",
            {
              staticClass: "cursor-pointer",
              attrs: {
                "vs-custom-content": "",
                disabled: _vm.audioVideoStarted,
                "vs-trigger-click": "",
              },
            },
            [
              _c(
                "div",
                { staticClass: "con-img ml-3" },
                [
                  _c("Avatar", {
                    attrs: {
                      size: "40px",
                      photoURL: _vm.activeUserInfo.photoURL,
                      name: _vm.displayName,
                    },
                  }),
                ],
                1
              ),
              _c("vs-dropdown-menu", { staticClass: "vx-navbar-dropdown" }, [
                _c(
                  "ul",
                  { staticStyle: { "min-width": "12rem" } },
                  [
                    _vm.activeUserInfo.impersonated
                      ? _c(
                          "li",
                          {
                            staticClass:
                              "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                            on: {
                              click: function ($event) {
                                return _vm.loginBack(_vm.activeUserInfo.ipuid)
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              attrs: {
                                icon: "LogInIcon",
                                svgClasses: "w-4 h-4",
                              },
                            }),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(_vm._s(_vm.activeUserInfo.ipname)),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.activeUserInfo.impersonated
                      ? _c("vs-divider", { staticClass: "m-1" })
                      : _vm._e(),
                    _vm.activeUserInfo.company &&
                    _vm.activeUserInfo.role !== "financial"
                      ? _c(
                          "li",
                          {
                            staticClass:
                              "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                            on: {
                              click: function ($event) {
                                _vm.$router
                                  .push("/user-settings")
                                  .catch(function () {})
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              attrs: {
                                icon: "SettingsIcon",
                                svgClasses: "w-4 h-4",
                              },
                            }),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(_vm._s(_vm.$t("vue.settings"))),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.activeUserInfo.superadmin && _vm.version
                      ? _c(
                          "li",
                          {
                            staticClass:
                              "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                            on: { click: _vm.updateVersion },
                          },
                          [
                            _c(
                              "span",
                              [
                                _c(
                                  "vs-chip",
                                  {
                                    staticClass: "ag-grid-cell-chip",
                                    attrs: { color: "#275D73" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "ShareIcon",
                                            svgClasses: "w-4 h-4",
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("vue.version")) +
                                            " " +
                                            _vm._s(_vm.version) +
                                            "\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    !_vm.isMobile
                      ? [
                          !_vm.activeUserInfo.role ||
                          _vm.activeUserInfo.role === "admin"
                            ? _c(
                                "li",
                                {
                                  staticClass:
                                    "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                                  on: {
                                    click: function ($event) {
                                      _vm.$router
                                        .push("/company-setup")
                                        .catch(function () {})
                                    },
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "BoxIcon",
                                      svgClasses: "w-4 h-4",
                                    },
                                  }),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("routes.companySetup"))
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.activeUserInfo &&
                          ![
                            "financial",
                            "agent",
                            "supervisor",
                            "view-only",
                          ].includes(_vm.activeUserInfo.role) &&
                          _vm.company &&
                          !_vm.company.isShopifyAccount &&
                          !_vm.company.isAppSumoAccount
                            ? _c("vs-divider", { staticClass: "m-1" })
                            : _vm._e(),
                          _vm.activeUserInfo &&
                          ![
                            "financial",
                            "agent",
                            "supervisor",
                            "view-only",
                          ].includes(_vm.activeUserInfo.role) &&
                          _vm.company &&
                          !_vm.company.isShopifyAccount &&
                          !_vm.company.isAppSumoAccount
                            ? _c(
                                "li",
                                {
                                  staticClass:
                                    "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                                  on: {
                                    click: function ($event) {
                                      _vm.$router
                                        .push("/payment")
                                        .catch(function () {})
                                    },
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "CreditCardIcon",
                                      svgClasses: "w-4 h-4",
                                    },
                                  }),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("vue.paymentSettings"))
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.activeUserInfo &&
                          !["financial", "agent", "supervisor"].includes(
                            _vm.activeUserInfo.role
                          ) &&
                          _vm.company &&
                          _vm.company.isAppSumoAccount
                            ? _c("vs-divider", { staticClass: "m-1" })
                            : _vm._e(),
                          _vm.activeUserInfo &&
                          !["financial", "agent", "supervisor"].includes(
                            _vm.activeUserInfo.role
                          ) &&
                          _vm.company &&
                          _vm.company.isAppSumoAccount
                            ? _c(
                                "li",
                                {
                                  staticClass:
                                    "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                                  on: {
                                    click: function ($event) {
                                      _vm.$router
                                        .push("/appsumo-billing")
                                        .catch(function () {})
                                    },
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "CreditCardIcon",
                                      svgClasses: "w-4 h-4",
                                    },
                                  }),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("routes.appsumoMyPlan"))
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _c("vs-divider", { staticClass: "m-1" }),
                    _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.changeLanguage("de")
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "FlagIcon", svgClasses: "w-4 h-4" },
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(_vm.$t("vue.deutsch"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.changeLanguage("en")
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "FlagIcon", svgClasses: "w-4 h-4" },
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(_vm.$t("vue.english"))),
                        ]),
                      ],
                      1
                    ),
                    _vm.activeUserInfo.company
                      ? _c("vs-divider", { staticClass: "m-1" })
                      : _vm._e(),
                    _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                        on: { click: _vm.logout },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "LogOutIcon", svgClasses: "w-4 h-4" },
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(_vm.$t("vue.logout"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }